<script>
import { mapGetters } from 'vuex';
import APIAffiliate from '@app/services/API/Affiliate';
import AffiliateForm from '@app/components/AffiliateForm.vue';

export default {
  components: { AffiliateForm },
  data: () => ({ affiliate: null }),
  computed: {
    ...mapGetters('auth', {
      authStore: 'store',
    }),
  },
  created() {
    const loader = this.$buefy.loading.open();
    APIAffiliate.getAffiliate(
      this.authStore.uuid,
      this.$route.params.uuid,
    )
      .then(({ data }) => {
        this.affiliate = data;
      })
      .finally(() => loader.close());
  },
};
</script>

<template>
  <b-modal
    v-if="affiliate"
    :can-cancel="false"
    hasModalCard
    active
  >
    <AffiliateForm
      :affiliate="affiliate"
      @update="$router.push({ name: 'affiliates' })"
      @cancel="$router.push({ name: 'affiliates' })"
    />
  </b-modal>
</template>
